<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    hideTopBar: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    cardClasses: {
      type: String,
      default: "",
    },
    closeable: {
      type: Boolean,
      default: true,
    },
    overflowed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <transition
    enter-active-class="animate__fadeIn animation_duration-200ms"
    leave-active-class="animate__fadeOut animation_duration-200ms"
  >
    <div
      v-if="modelValue"
      as="div"
      class="animate__animated fixed inset-0 z-20 flex justify-center bg-gray-700 bg-opacity-75 print:absolute print:block print:h-full print:overflow-visible"
      :class="[
        {
          'items-center': !overflowed,
          'overflow-y-auto py-10 print:py-0': overflowed,
        },
      ]"
      @mousedown="closeable && $emit('update:modelValue', false)"
    >
      <div
        class="my-auto transform overflow-hidden rounded-lg bg-white p-10 pt-8 text-left align-bottom shadow-xl transition-all md:w-full md:max-w-fit print:block print:!h-full print:!min-h-full print:overflow-visible print:rounded-none print:shadow-none"
        :class="[
          cardClasses,
          {
            'flex max-h-[80vh] flex-col md:h-auto': !overflowed,
            'h-fit': overflowed,
          },
        ]"
        @mousedown.stop
      >
        <div v-if="!hideTopBar" class="mb-8 flex items-center text-gray-800">
          <h1 class="flex-1 text-2xl font-semibold">
            {{ title }}
          </h1>
          <div
            v-if="closeable"
            class="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full border border-white/5 bg-white/10 hover:bg-gray-100"
            @click="$emit('update:modelValue', false)"
          >
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <div class="flex-grow print:bg-white">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>
